<template>
  <div>
    <b-button :variant="d_variant" :disabled="d_disabled" @click="onClick">{{ d_caption }}</b-button>
  </div>
</template>

<script>
import { RST_LEVEL, RST_REF_EVENT, RST_REF_CATEGORY, RST_EVENT } from '@/LocalDB';
import EventsConfigurationHelper from '@/services/helpers/eventsConfigurationHelper';

export default{
  props: {
    competition_id: { type: String, required: true },
    round_number: { type: Number, required: true },
    level: { type: String, required: true },
    category: { type: String, required: true },
    event: { type: String, required: true}
  },
  //components: { CollapsePanel, },
  data(){
    return {
      d_competition_id: this.competition_id,
      d_round_number: this.round_number,
      d_level: this.level,
      d_category: this.category,
      d_event: this.event,

      dataSynchronized: false,

      d_disabled: false,
      d_variant: '',
      d_caption: '',
    }
  },
  methods:{
    async refresh(){
      this.dataSynchronized = await EventsConfigurationHelper.refreshEvent(this.d_competition_id, this.d_round_number, this.d_level, this.d_event, this.d_category);

      this.levels = RST_LEVEL.query().where("competition_id", this.d_competition_id).get().map(rstLevel => {
        //var level = { ...rstLevel, ...rstLevel.LEVEL,
        //  variantTheme: rstLevel.isCompleted ? 'success' : (rstLevel.isStarted ? 'warning' : 'secondary'),
        //  variantText: rstLevel.Name + rstLevel.isCompleted ? ' - Terminé' : (rstLevel.isStarted ? ' - En cours': '')
        //};

        var allEventsTypes = RST_REF_EVENT.query().where('competition_id', this.d_competition_id).where("level", rstLevel.level).get();
        allEventsTypes.sort((a,b) => {
          var diffRoundNumber = a.round_number - b.round_number;
          if(diffRoundNumber != 0)
            return diffRoundNumber;
          return a.displayOrder - b.displayOrder;
          /*var diffSortOrder = a.EVENT.name.localeCompare(b.EVENT.name);
          if(diffSortOrder != 0)
            return diffSortOrder;*/
        });

        var rounds = allEventsTypes
                        .map(evtType => evtType.round_number)
                        .filter((value, index, array) => array.indexOf(value) == index);

        var allCategories = RST_REF_CATEGORY.query().where('competition_id', this.d_competition_id).where("level", rstLevel.level).get();
        allCategories.sort((a,b) => {
          return a.CATEGORY.displayOrder - b.CATEGORY.displayOrder;
        });

        rounds = rounds.map(rnd => {
          var allRoundEvents = [];
          var allCompleted = true;
          var noneStarted = true;
          allEventsTypes.filter(evtType => evtType.round_number == rnd).forEach(evtType => {
            allCategories.filter(car => car.effective_competitors_count > 0).forEach(cat => {
              var evt = RST_EVENT.query().where("competition_id", this.d_competition_id).where("level", evtType.level).where("round_number", rnd).where("category", cat.category).first();
              if(evt == null){
                evt = new RST_EVENT();
                evt.competition_id = this.d_competition_id;
                evt.level = evtType.level;
                evt.event = evtType.event;
                evt.category = cat.category;
                evt.round_number = rnd;
              }
              var disabled = false;
              // check disabled vs previous round
              if(!disabled && evt.round_number > 1 && !evt.isCompleted)
              {
                var prevEvent = evt.PREVIOUS_ROUND_EVENT;
                disabled = prevEvent == null || !prevEvent.isCompleted;
              }
              // check disabled vs same category
              if(!disabled)
                disabled = RST_EVENT.query().where("competition_id", evt.competition_id).where("round_number", evt.round_number).where("level", evt.level).where("category", evt.category).where(e => e.event != evt.event && e.isStarted && !e.isCompleted).exists();
              evt.disabled = disabled;
              //console.log('evt/categ', evt, cat);
              if( evt.isCompleted){
                evt.variantText = "Résultats " + evt.EVENT.shortname + " " + evt.round_number + " " + evt.CATEGORY.shortname;
                evt.variantTheme = "success";
                evt.action = "showResults";
                noneStarted = false;
              } else if (evt.isStarted)
              {
                allCompleted = false;
                evt.variantText = "Reprendre " + evt.EVENT.shortname + " " + evt.round_number + " " + evt.CATEGORY.shortname;
                evt.variantTheme = "warning";
                evt.action = "continueEvent";
              }else{
                allCompleted = false;
                noneStarted = false;
                evt.variantText = "Démarrer " + evt.EVENT.shortname + " " + evt.round_number + " " + evt.CATEGORY.shortname;
                evt.variantTheme = "primary";
                evt.action = "startEvent";
              }
              allRoundEvents.push(evt);
            });
          });
          var variantText = allCompleted ? "Résultats manche " + rnd : (noneStarted ? "Manche " + rnd + " (non commencée)" : "Manche " + rnd + " (en cours)");
          var variantTheme = allCompleted ? "success" : (noneStarted ? "primary" : "warning");
          return {
            number: rnd,
            events: allRoundEvents,
            key: rstLevel.level + "_" + rnd,
            variantText: variantText,
            variantTheme: variantTheme,

          };
        });
        return { ...rstLevel, ...rstLevel.LEVEL,
          variantTheme: rstLevel.isCompleted ? 'success' : (rstLevel.isStarted ? 'warning' : 'secondary'),
          variantText: rstLevel.Name + rstLevel.isCompleted ? ' - Terminé' : (rstLevel.isStarted ? ' - En cours': ''),
          rounds: rounds
        };
      });
    },
  },
  async mounted(){
    await this.refresh();
  },
  watch:{
    event: async function(newVal){
      this.d_event = newVal;
      await this.refresh();
    }
  }
}
</script>
